import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ScrollContainer from "react-indiana-drag-scroll";

import clickImage from "../../../static/images/click-view.png";

const Container = styled.div`
  background: #ffffff;
  border-radius: 3px;
  overflow: hidden;
  display: block;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  .edit-project {
    margin-top: 10px;
    position: absolute;
    top: 0;
    right: 10px;
    background: #f1a857;
    border-radius: 3px;
    width: 50px;
    height: 50px;
    padding: 13px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.2s all ease;
    z-index: 1;

    i {
      background: url("/images/streamline-icon-pencil-write-2-140-x-140.png")
        center no-repeat;
      background-size: 24px;
      width: 24px;
      height: 24px;
      display: block;
    }

    &:hover {
      background: #e59134;
    }
  }
  .image-container {
    height: 286px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: block;
    position: relative;

    background-color: #9e9e9e;

    &:hover .hover-image {
      opacity: 1;
      visibility: visible;
    }

    .hover-image {
      opacity: 0;
      visibility: hidden;

      transition: 0.3s all ease;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background: rgba(0, 0, 0, 0.5);
      padding-top: 60px;

      display: flex;
      align-items: center;
      flex-direction: column;

      .hover-center {
        text-align: center;

        img {
          width: 32px;
        }

        p {
          font: italic bold 16px "Times new roman";
          color: #ffffff;
          margin-bottom: 0;
          margin-top: 10px;
        }
      }

      .hover-footer {
        margin-top: auto;
        width: 100%;

        h4 {
          font: italic bold 20px "Times new roman";
          color: #ffffff;
          margin-bottom: 7px;
          text-align: center;
        }

        ul {
          display: flex;
          padding: 0 15px 15px;

          li {
            margin-bottom: 0;
            padding-right: 15px;

            span {
              display: flex;
              align-items: center;

              padding: 2px;
              background: #ffffff;
              border-radius: 3px;
              width: 150px;

              img {
                width: 50px;
                height: 50px;
                border-radius: 3px;
                margin-right: 10px;
              }

              p {
                font: italic bold 16px "Times new roman";
                color: #747474;
                margin-bottom: 0;
                margin-right: 10px;
              }

              span {
                text-transform: uppercase;
                font-size: 11px;
                color: #747474;
                background: none;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 767px) {
    .image-container {
      height: 265px;

      .hover-image {
        padding-top: 45px;
      }
    }
  }

  @media (min-width: 1200px) {
    .image-container {
      height: 270px;

      .hover-image {
        padding-top: 55px;
      }
    }
  }

  .project-info {
    padding: 20px 15px 11px;

    h3 {
      font: italic bold 20px "Times new roman";
      color: #323232;
      margin-bottom: 20px;
      text-align: left;
    }

    & > span {
      display: block;
      font-size: 12px;
      color: #959595;
      text-align: left;

      i {
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .project-info-footer {
      margin-top: 18px;
      padding-top: 8px;
      border-top: 1px solid #f4f7f6;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .author {
        display: flex;
        align-items: center;

        span {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-size: cover;
          display: inline-block;
          background-position: center;
        }

        h4 {
          font: italic bold 13px "Times new roman";
          color: #323232;
          margin-left: 10px;
        }
      }

      .likes {
        display: flex;
        align-items: center;

        i {
          margin-right: 7px;
          width: 20px;
          height: 20px;
          background: url("/images/heart.svg") no-repeat center;
          background-size: contain;
        }

        p {
          font-size: 12px;
          color: #959595;
          margin: 0;
        }
      }
    }
  }
`;

export default function ProjectCard({
  id,
  image,
  name,
  city,
  uf,
  authorImage,
  authorName,
  numberOfLikes,
  products,
  noLink,
}) {
  const getImage = (value) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return [];
    }
  };
  const getThumbnail = (url) => {
    let imageArray = url.split("/");
    let imageName = imageArray[imageArray.length - 1];
    let imageThumb =
      "https://apimeuambiente.guararapes.com.br/assets/" + imageName;

    return imageThumb;
  };
  const gallery = getImage(image)[0];
  const productsParsed = getImage(products);
  const content = (
    <div>
      <div
        className="image-container"
        style={
          gallery
            ? { backgroundImage: `url(${getThumbnail(gallery.url)})` }
            : null
        }
      >
        <div className="hover-image">
          {window.location.href.indexOf("meu-perfil") !== -1 ? (
            <Link
              to={`/editar-ambiente/${id}`}
              state={{
                modal: true,
              }}
              className="edit-project"
            >
              <i></i>
            </Link>
          ) : (
            <span></span>
          )}
          <div className="hover-center">
            <img src={clickImage} alt="Clique para ver o projeto" />
            <p>Clique para ver o projeto</p>
          </div>

          {productsParsed && productsParsed.length > 0 && (
            <div className="hover-footer">
              <h4>Produtos utilizados</h4>
              <ScrollContainer className="scroll-container">
                <ul>
                  {productsParsed &&
                    productsParsed.map((product, index) => (
                      <li key={index}>
                        {product ? (
                          <span>
                            {product.node ? (
                              <>
                                <img
                                  src={product.node.data.imagem_principal.url}
                                  alt={product.node.data.nome[0].text}
                                />
                                <div>
                                  <p>{product.node.data.nome[0].text}</p>
                                </div>
                              </>
                            ) : (
                              <>
                                <img
                                  src={product.data.imagem_principal.url}
                                  alt={product.data.nome[0].text}
                                />
                                <div>
                                  <p>{product.data.nome[0].text}</p>
                                </div>
                              </>
                            )}
                          </span>
                        ) : (
                          <></>
                        )}
                      </li>
                    ))}
                </ul>
              </ScrollContainer>
            </div>
          )}
        </div>
      </div>
      <div className="project-info">
        <h3>{name}</h3>
        <span>
          {city}, <i>{uf}</i>
        </span>

        <div className="project-info-footer">
          <div className="author">
            <span style={{ backgroundImage: `url(${authorImage})` }}></span>
            <h4>{authorName}</h4>
          </div>

          <div className="likes">
            <i></i>
            <p>{numberOfLikes}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Container>
      {!noLink ? (
        <Link to={`/ambiente/${id}`} state={{ modal: true }}>
          {content}
        </Link>
      ) : (
        content
      )}
    </Container>
  );
}
